// jshint strict:false, undef:false, unused:false

/*/
	Lazy Vimeo Embed
	Stephen Ginn
	Last Updated 2019-12-23

	This function embeds vimeo videos using their api, so that we can eliminate
	all their messy error-prone tracking code. The video is ONLY inserted when
	clicked, which allows us to embed multiple videos per page, without
	impacting performance.
/*/

function embedVimeo(selector) {
	selector = selector || '[data-type="vimeo"]';

	var videos = document.querySelectorAll(selector);
	var promiseList = [];

	for (var i = 0; i < videos.length; i++) {
		promiseList[i] = new Promise((resolve, reject) => {
			var video = videos[i],
				embed_id = video.getAttribute('data-embed');

			console.log(embed_id);

			fetch(`https://vimeo.com/api/v2/video/${embed_id}.json`).then(response => {
				return response.json();
			}).then(json => {
				resolve(json[0]);
			});
		});
	}

	Promise.all(promiseList).then(json => {
		json.forEach((data, i) => {
			var video = videos[i],
				embed_id = video.getAttribute('data-embed'),
				img_url = data.thumbnail_large.replace("_640", "?mw=1080&q=50"),
				img = `<img src="${img_url}">`;

			console.log(data);

			video.insertAdjacentHTML('beforeend', img);

			video.addEventListener("click", () => {
				var iframe_src = `https://player.vimeo.com/video/${embed_id}?rel=0&showinfo=0&autoplay=1`,
					iframe = `<iframe src="${iframe_src}" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;

				video.innerHTML = ""
				video.insertAdjacentHTML('afterbegin', iframe);
			});
		});
	});
}
