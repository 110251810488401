// jshint strict:false, undef:false, unused:false

/*!
	@name:		BS3 Custom Controls
	@version:	0.22
	@author:	Stephen Ginn
	@changed: 	2019-05-17

	Updated markup is needed for styling active checkbox replacements.
	This helper script adds BS4 classes and html markup to old BS3 forms.
	However, this script can be removed if you manually upgrade your forms.

	Converts this...
	<div class="checkbox m-b">
		<label>
			<input type="checkbox"> Your Checkbox
		</label>
	</div>

	...to this:
	<div class="checkbox m-b">
		<label class="custom-control custom-checkbox">
			<input type="checkbox" class="custom-control-input">
			<div class="custom-control-label">Your Checkbox</div>
		</label>
	</div>

	HOW TO INITIALIZE
	custom_controls();
/*/


function custom_controls() {
	var inputs = document.querySelectorAll(".checkbox label input, .radio label input, select");

	for (var i = 0; i < inputs.length; i++) {
		var input = inputs[i];

		if (input.type === "select-one") {
			input.classList.add("custom-control");
		} else {
			var label = input.parentElement;

			input.classList.add("custom-control-input");
			label.classList.add("custom-control", "custom-" + input.type);

			var inputHTML = input.outerHTML;
			label.removeChild(input);
			label.innerHTML = inputHTML + '<div class="custom-control-label">' + label.innerHTML + '</div>';
		}
	}
}
