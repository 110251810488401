/*/
	GPC Vendor Loader
	Dynamically loads GPC vendors using an excel spreadsheet
	Coded by Stephen Ginn
	Updated 2020-01-29
/*/

function extractHeader(ws) {
  const header = []
  const columnCount = XLSX.utils.decode_range(ws['!ref']).e.c + 1
  for (let i = 0; i < columnCount; ++i) {
    header[i] = ws[`${XLSX.utils.encode_col(i)}1`].v
  }
  return header
}

function importData(url) {
	return new Promise((resolve, reject) => {		
		fetch(url).then(res => {
			return res.arrayBuffer();
		}).then(response => {
			// Convert data to binary string
			var data = new Uint8Array(response),
				arr = [];

			for (var i = 0; i < data.length; ++i) {
				arr[i] = String.fromCharCode(data[i]);
			}
			
			// Convert first worksheet to JSON
			var workbook = XLSX.read(arr.join(""), {type: "binary"}),
				worksheet = workbook.Sheets[workbook.SheetNames[0]],
				objects = XLSX.utils.sheet_to_json(worksheet, {raw: false});
			
			// const headers = extractHeader(worksheet);
			// console.log(headers);
									
			objects.forEach((obj, i) => {
				// Lowercase keys and trim whitespace from keys and values
				obj = Object.fromEntries(
					Object.entries(obj).map(([k, v]) => [k.toLowerCase().trim(), v.trim()])
				);

				objects.push(obj);
				
				delete objects[i];
			});			
						
			// Return JSON Object
			resolve(objects.filter(n => n));
		}).catch(err => {
			reject(err);
		});
	});
}

function populateData(url) {
    importData(url).then(vendors => {	
        var target = document.querySelector("#target");

        var headers = ["name", "location", "discount", "website", "contact"];

        var headers2 = Object.values(vendors).keys();
        console.log(headers2);

        vendors.forEach(vendor => {
            headers.forEach(header => {						
                if (! vendor[header]) {
                    vendor[header] = '';
                } else {
                    vendor[header] = vendor[header].split(',').join('<br>');
                }
            });

            var template = `<tr>
                <th scope="row">${vendor.name}</th>
                <td>${vendor.location}</td>
                <td align="right">${vendor.discount}</td>
                <td>${vendor.website}</td>
                <td>${vendor.contact}</td>
            </tr>`;

            target.insertAdjacentHTML('beforeend', template);
        });
    });
}
